@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .spnone {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .bp1200none {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (max-width: 1000px) {
  .bp1000none {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

.disnone {
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.8em;
  font-weight: 400;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow-x: hidden;
  letter-spacing: 0.05em;
  font-size: 15px;
}

.inner {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  z-index: 3;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #contents {
    padding: 0 30px 50px;
  }
}

@media only screen and (max-width: 640px) {
  #contents {
    padding: 0 40px 50px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #ff0000;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  z-index: 3;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 80px;
  }
}

.bgh {
  background: rgba(63, 154, 221, 0.9);
}

.bgh .store2 {
  background: #fff !important;
}

.bgh .store2 a {
  color: #333 !important;
}

.header {
  padding: 10px 0 0;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .header {
    height: 80px;
    padding: 0;
    background: rgba(63, 154, 221, 0.7);
  }
}

.header .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .header .inner {
    padding: 0 10px;
  }
}

.header .flxL {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .header .flxL {
    width: 50%;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .header .flxL {
    width: 70%;
  }
}

.header .flxR {
  width: 75%;
}

@media only screen and (max-width: 834px) {
  .header .flxR {
    width: 100%;
  }
}

#hednav {
  z-index: 1;
}

@media only screen and (max-width: 834px) {
  #hednav {
    margin-left: auto;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
  }
}

#hednav .navwrap {
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
}

@media only screen and (max-width: 834px) {
  #hednav .navwrap {
    display: block;
  }
}

#hednav .navwrap #nav_global {
  width: 100%;
}

#hednav .navwrap #nav_global > ul {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

#hednav .navwrap #nav_global > ul > li {
  position: relative;
  height: 100%;
  flex-grow: 1;
}

#hednav .navwrap #nav_global > ul > li a, #hednav .navwrap #nav_global > ul > li p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px 0;
  color: #fff;
}

#hednav .navwrap #nav_global > ul > li a:hover, #hednav .navwrap #nav_global > ul > li p:hover {
  background: #357fb6;
}

#hednav .navwrap #nav_global > ul > li ul {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(63, 154, 221, 0.7);
}

#hednav .navwrap #nav_global > ul > li ul li a {
  padding: 10px;
}

#hednav .navwrap #hed_info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}

#hednav .navwrap #hed_info .hoverbtn {
  transition: 1s;
}

#hednav .navwrap #hed_info .hoverbtn a {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: 1;
}

#hednav .navwrap #hed_info .hoverbtn a i {
  margin-right: 10px;
}

#hednav .navwrap #hed_info .hoverbtn a span {
  list-style: 1;
}

#hednav .navwrap #hed_info .store2 {
  padding: 10px 20px;
  background: #3f9add;
  text-align: center;
  border-radius: 30px;
}

#hednav .navwrap #hed_info .store2:hover {
  background: #357fb6;
}

#hednav .navwrap #hed_info .store2 a {
  font-size: 16px;
}

#hednav .navwrap #hed_info .store1 {
  margin-right: 10px;
}

@media only screen and (max-width: 834px) {
  #hednav .navwrap #hed_info {
    width: 100%;
    display: block;
  }
  #hednav .navwrap #hed_info .store2 {
    padding: 0;
    background: none;
    border-radius: 0;
  }
  #hednav .navwrap #hed_info .store2:hover {
    background: none;
  }
  #hednav .navwrap #hed_info .store1 {
    margin-right: 0;
  }
  #hednav .navwrap #hed_info .hoverbtn {
    width: 90%;
    margin-top: 10px;
    margin: 10px auto 0;
  }
  #hednav .navwrap #hed_info .hoverbtn a {
    justify-content: center;
    padding: 10px;
    background: #ffa0c3;
  }
  #hednav .navwrap #hed_info .hoverbtn a:hover {
    background: #dc8aa8;
  }
  #hednav .navwrap #hed_info .hoverbtn a i {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 834px) {
  @-webkit-keyframes menu-bar01 {
    0% {
      -webkit-transform: translateY(9px) rotate(45deg);
    }
    50% {
      -webkit-transform: translateY(9px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(0) rotate(0);
    }
  }
  @keyframes menu-bar01 {
    0% {
      transform: translateY(9px) rotate(45deg);
    }
    50% {
      transform: translateY(9px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
  @-webkit-keyframes menu-bar02 {
    0% {
      -webkit-transform: translateY(-9px) rotate(-45deg);
    }
    50% {
      -webkit-transform: translateY(-9px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(0) rotate(0);
    }
  }
  @keyframes menu-bar02 {
    0% {
      transform: translateY(-9px) rotate(-45deg);
    }
    50% {
      transform: translateY(-9px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
  .global-menu-btn {
    position: relative;
    cursor: pointer;
    height: 80px;
    width: 80px;
    font-size: 13px;
    z-index: 11;
    line-height: 1;
    transition: 1s;
    color: #fff;
    background: #3f9add;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .global-menu-btn::after {
    content: "MENU";
    width: 100%;
    text-align: center;
    font-size: 11px;
    letter-spacing: .5px;
    margin-top: 10px;
  }
  .global-menu-btn .menu-trigger {
    position: relative;
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .global-menu-btn .menu-trigger i {
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    position: absolute;
  }
  .global-menu-btn .menu-trigger i:nth-child(1) {
    top: 0;
    animation: menu-bar01 .75s forwards;
  }
  .global-menu-btn .menu-trigger i:nth-child(2) {
    width: 20px;
    top: 9px;
    opacity: 1;
  }
  .global-menu-btn .menu-trigger i:nth-child(3) {
    bottom: 0;
    animation: menu-bar02 .75s forwards;
  }
  .navdamy {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  #hednav {
    justify-content: center;
    height: 80px;
    width: 80px;
    z-index: 14;
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    top: 0;
    right: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #eef2f2;
    width: 70%;
    height: 100%;
    opacity: 0;
    margin-left: auto;
    transform: translateX(240%);
    transition: all 0.5s ease-out;
    padding-top: 80px;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
    background: none;
  }
  #hednav .wrap .navwrap #nav_global > ul {
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global > ul .pcnone {
    display: block !important;
  }
  #hednav .wrap .navwrap #nav_global > ul .clonenone {
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li {
    text-align: left;
    position: relative;
    width: 100%;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p {
    line-height: 1.7em;
    color: #000;
    font-weight: 700 !important;
    display: block;
    padding: 26px 36px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p:hover {
    opacity: 1;
    color: #fff;
    background: #3f9add !important;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p img {
    width: 25%;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p span {
    width: 70%;
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p span b {
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p span span {
    display: block;
    font-size: 0.8em;
    color: #333;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a {
    line-height: 1.7em;
    color: #000;
    font-weight: 700 !important;
    display: block;
    padding: 26px 36px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a:hover {
    opacity: 1;
    color: #fff;
    background: #3f9add !important;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a img {
    width: 25%;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a span {
    width: 70%;
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a span b {
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a span span {
    display: block;
    font-size: 0.8em;
    color: #333;
  }
  #hednav .wrap .navwrap #nav_global > ul > li ul {
    display: block;
    width: 100%;
    position: static;
    background: none;
  }
  #hednav .wrap .navwrap #nav_global > ul > li ul li a {
    background: #f7f7f7;
    color: #333;
    font-weight: 400;
    display: block;
    font-size: 14px;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p {
    position: relative;
    display: block;
    line-height: 1;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p:hover:after {
    color: #333;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p span {
    display: block;
    margin-top: 7px;
    font-size: 0.7em;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p:after {
    border: solid 1px #ddd;
    font-weight: 100;
    content: "\f067";
    font-family: "Font Awesome 5 Pro";
    display: block;
    position: absolute;
    right: 0;
    top: -1px;
    bottom: 0;
    width: 68px;
    height: 68px;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f7f6;
    z-index: 1;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p.open:after {
    content: "\f00d";
  }
  @-webkit-keyframes active-menu-bar01 {
    0% {
      -webkit-transform: translateY(0) rotate(0);
    }
    50% {
      -webkit-transform: translateY(20px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(20px) rotate(45deg);
    }
  }
  @keyframes active-menu-bar01 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(9px) rotate(0);
    }
    100% {
      transform: translateY(9px) rotate(45deg);
    }
  }
  @-webkit-keyframes active-menu-bar03 {
    0% {
      -webkit-transform: translateY(0) rotate(0);
    }
    50% {
      -webkit-transform: translateY(-9px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(-9px) rotate(-45deg);
    }
  }
  @keyframes active-menu-bar03 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(-9px) rotate(0);
    }
    100% {
      transform: translateY(-9px) rotate(-45deg);
    }
  }
  .MenuOpened {
    z-index: 13 !important;
    display: none;
    display: block;
  }
  .MenuOpened .global-menu-btn {
    z-index: 11;
  }
  .MenuOpened .global-menu-btn i:nth-child(1) {
    animation: active-menu-bar01 .75s forwards;
  }
  .MenuOpened .global-menu-btn i:nth-child(2) {
    opacity: 0;
  }
  .MenuOpened .global-menu-btn i:nth-child(3) {
    animation: active-menu-bar03 .75s forwards;
  }
  .MenuOpened .wrap {
    z-index: 10 !important;
    visibility: visible !important;
    opacity: 1 !important;
    background: rgba(0, 0, 0, 0.7) !important;
  }
  .MenuOpened .wrap .navwrap {
    opacity: 1 !important;
    -webkit-transform: translateX(0) !important;
    -ms-transform: translateX(0) !important;
    transform: translateX(0) !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh !important;
    margin-left: auto;
  }
}

#mainArea2 {
  z-index: 0;
  position: relative;
  background: url(../images/common/main_1_pc.jpg) bottom no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 834px) {
  #mainArea2 {
    margin-top: 0 !important;
  }
}

#local-keyvisual {
  max-width: 100%;
  margin: 0 auto;
}

#local-keyvisual #local-keyvisual-title {
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: relative;
  width: 100%;
  padding: 200px 0;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual #local-keyvisual-title {
    padding: 150px 10px;
  }
}

#local-keyvisual #local-keyvisual-title h1 {
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1;
  color: #fff !important;
  z-index: 1;
  position: relative;
  height: 100%;
  line-height: 1.4em;
  display: inline-block;
  font-size: 30px;
}

#local-keyvisual #local-keyvisual-title h1:empty {
  display: none;
}

#local-keyvisual .local-keyvisual-bg {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual .local-keyvisual-bg {
    margin-left: 0 !important;
  }
}

.pan1 {
  margin: 50px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #221815;
}

.pan1 ol li {
  display: inline-block;
}

#mainArea {
  position: relative;
  z-index: 0;
}

#mainArea .maintxt {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

#mainArea .maintxt h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-size: 45px;
  font-weight: 900;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  line-height: 1;
}

@media only screen and (max-width: 834px) {
  #mainArea .maintxt h1 {
    font-size: 30px;
  }
}

#mainArea .maintxt h1 span {
  font-size: 40px;
  font-weight: 900;
  display: inline-block;
  background: #3f9add;
  margin-top: 20px;
  padding: 10px 15px;
  text-shadow: none;
}

@media only screen and (max-width: 834px) {
  #mainArea .maintxt h1 span {
    font-size: 25px;
  }
}

#mainArea .maintxt p {
  text-align: left;
}

#mainArea img {
  width: 100% !important;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  z-index: 1;
  position: relative;
}

footer#global_footer a {
  color: #3f9add;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer {
  color: #333;
  background: url(../images/common/cont_bg.jpg) center top no-repeat;
  background-size: cover;
}

#footer .inner {
  text-align: left;
  max-width: 1200px;
  padding: 80px 0;
}

@media only screen and (max-width: 1200px) {
  #footer .inner {
    padding: 80px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer .inner {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner {
    padding: 50px 40px;
  }
}

#footer .inner .wrap2 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 70%;
  padding: 50px;
  background: rgba(255, 255, 255, 0.6);
}

@media only screen and (max-width: 834px) {
  #footer .inner .wrap2 {
    width: 100%;
  }
}

#footer .inner .wrap2 .ttl {
  color: #222;
  font-weight: 700;
  line-height: 1.7em;
  margin-bottom: 30px;
  font-size: 30px;
}

@media only screen and (max-width: 1000px) {
  #footer .inner .wrap2 .ttl {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  #footer .inner .wrap2 .ttl {
    text-align: center;
  }
}

#footer .inner .wrap2 > p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 834px) {
  #footer .inner .wrap2 > p {
    text-align: center;
  }
}

@media only screen and (max-width: 834px) {
  #footer .inner .wrap2 .wrap {
    display: block;
    width: 100%;
  }
}

#footer .inner .wrap2 .wrap .tel2 {
  margin-bottom: 15px;
}

@media only screen and (max-width: 834px) {
  #footer .inner .wrap2 .wrap .tel2 {
    text-align: center;
  }
}

#footer .inner .wrap2 .wrap .tel2 a {
  color: #222;
  font-weight: 700;
  line-height: 1.7em;
  font-size: 20px;
}

@media only screen and (max-width: 1000px) {
  #footer .inner .wrap2 .wrap .tel2 a {
    font-size: 18px;
  }
}

#footer .inner .wrap2 .wrap .tel2 a span {
  font-size: 30px;
}

@media only screen and (max-width: 1000px) {
  #footer .inner .wrap2 .wrap .tel2 a span {
    font-size: 25px;
  }
}

#footer .inner .wrap2 .wrap .cont {
  display: block;
  max-width: 300px;
  margin: 0 auto 0 0;
}

@media only screen and (max-width: 834px) {
  #footer .inner .wrap2 .wrap .cont {
    margin: 0 auto;
  }
}

#footer .inner .wrap2 .wrap .cont a {
  width: 100%;
  margin-top: 10px;
  background: #3f9add;
  display: block;
  border-radius: 30px;
  padding: 10px;
  color: #fff;
  text-align: center;
  opacity: 1 !important;
}

#footer .inner .wrap2 .wrap .cont a:hover {
  background: #357fb6;
}

#footer2 {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px 0;
}

@media only screen and (max-width: 1200px) {
  #footer2 {
    padding: 50px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer2 {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 640px) {
  #footer2 {
    padding: 50px 40px;
  }
}

#footer2 .flxL {
  text-align: left;
}

@media only screen and (max-width: 834px) {
  #footer2 .flxL {
    text-align: center;
    margin: 0 auto;
  }
}

#footer2 .flxL #siteID {
  margin-bottom: 15px;
}

#footer2 .flxL p span {
  color: #ffa0c3;
}

#footer2 .flxR {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

@media only screen and (max-width: 834px) {
  #footer2 .flxR {
    display: none;
  }
}

#footer2 .flxR .nav_f {
  margin-left: 20px;
}

#footer2 .flxR ul li a {
  display: block;
  text-align: left;
  color: #333;
}

#copy {
  background: #3f9add;
  padding: 10px 0;
  text-align: center;
  font-size: 13px;
  display: block;
  line-height: 1.7em;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 13px;
  color: #fff !important;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media only screen and (max-width: 640px) {
  .progress-wrap {
    right: 10px;
    bottom: 140px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  opacity: 0;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
  box-shadow: 0px 3px 10px rgba(51, 51, 51, 0.1);
  border-radius: 50%;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #3f9add;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #3f9add;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #3f9add;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.btn {
  display: block;
  max-width: 500px;
  margin: 0 auto;
}

@media only screen and (max-width: 834px) {
  .btn {
    max-width: 100%;
  }
}

.btn a {
  width: 100%;
  margin-top: 10px;
  background: #3f9add;
  display: block;
  border-radius: 30px;
  padding: 10px;
  color: #fff;
  text-align: center;
  opacity: 1 !important;
}

.btn a:hover {
  background: #357fb6;
}

@media only screen and (max-width: 1200px) {
  .cont_1 {
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_1 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_1 {
    padding: 0 40px;
  }
}

.cont_1 .inner {
  z-index: 3;
  position: relative;
  padding: 30px 30px 0;
  background: #fff;
  margin: -200px auto 0;
}

@media only screen and (max-width: 1200px) {
  .cont_1 .inner {
    margin: 0 auto;
    padding: 0;
  }
}

.cont_1 .wrap {
  border: 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cont_1 .wrap .box {
  position: relative;
  width: 31%;
}

@media only screen and (max-width: 834px) {
  .cont_1 .wrap .box {
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_1 .wrap .box img {
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .cont_1 .wrap .box img {
    width: 100% !important;
  }
}

.cont_1 .wrap .box article {
  height: 100%;
  background: #fff;
}

.cont_1 .wrap .box article h3 {
  margin: 15px 0 10px;
  font-size: 23px;
  font-weight: 700;
  color: #222;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .cont_1 .wrap .box article h3 {
    font-size: 18px;
  }
}

.cont_1 .wrap .box article > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.cont_1 .wrap .box article a {
  width: 100%;
  margin-top: 10px;
  background: #3f9add;
  display: block;
  border-radius: 30px;
  padding: 10px;
  color: #fff;
  text-align: center;
  opacity: 1 !important;
}

.cont_1 .wrap .box article a:hover {
  background: #357fb6;
}

@media only screen and (max-width: 1200px) {
  .cont_2 {
    margin-left: 0 !important;
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_2 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_2 {
    padding: 0 40px;
  }
}

.cont_2 .inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .cont_2 .inner {
    flex-direction: column-reverse;
  }
}

.cont_2 .flxL {
  width: 65%;
}

@media only screen and (max-width: 1400px) {
  .cont_2 .flxL {
    width: 60%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 .flxL {
    width: 100%;
  }
}

.cont_2 .flxR {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  width: 60%;
}

@media only screen and (max-width: 1400px) {
  .cont_2 .flxR {
    width: 35%;
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.cont_2 .flxR img {
  width: 100% !important;
}

.cont_3 {
  padding: 80px 0;
  background: #3f9add;
}

@media only screen and (max-width: 1200px) {
  .cont_3 {
    padding: 80px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_3 {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 {
    padding: 50px 40px;
  }
}

.cont_3 .wrap {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1.63934%;
}

@media only screen and (max-width: 834px) {
  .cont_3 .wrap {
    justify-content: space-between;
    margin-left: 0;
  }
}

.cont_3 .wrap .box {
  position: relative;
  width: 23.36066%;
  margin-left: 1.63934%;
}

@media only screen and (max-width: 834px) {
  .cont_3 .wrap .box {
    width: 100%;
    margin-left: 0;
    padding: 0;
    margin-bottom: 30px;
  }
}

.cont_3 .wrap .box img {
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .cont_3 .wrap .box img {
    width: 100% !important;
  }
}

.cont_3 .wrap .box article {
  height: 100%;
}

.cont_3 .wrap .box article h3 {
  margin: 15px 0 10px;
  font-size: 23px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .cont_3 .wrap .box article h3 {
    font-size: 18px;
  }
}

.cont_3 .wrap .box article > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: #fff;
}

.cont_3 .wrap .box article a {
  color: #fff;
}

.cont_4 {
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .cont_4 {
    margin-left: 0 !important;
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_4 {
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_4 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_4 {
    padding: 0 40px;
  }
}

.cont_4 .inner2 {
  border: 10px solid #3f9add;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .cont_4 .inner2 {
    flex-direction: column-reverse;
  }
}

.cont_4 .inner2 .flxL {
  background: #fff;
  width: 70%;
}

@media only screen and (max-width: 834px) {
  .cont_4 .inner2 .flxL {
    width: 100%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_4 .inner2 .flxL div {
    text-align: center !important;
  }
}

.cont_4 .inner2 .flxR {
  width: 27%;
}

@media only screen and (max-width: 834px) {
  .cont_4 .inner2 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.cont_4 .inner2 .flxR .img {
  width: 100%;
}

.cont_4 .inner2 .flxR .img img {
  width: 100% !important;
}

.cont_4 .inner2 h3 {
  font-size: 20px;
  font-weight: 700;
  color: #222;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1000px) {
  .cont_4 .inner2 h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_4 .inner2 h3 {
    text-align: center !important;
  }
}

@media only screen and (max-width: 834px) {
  .cont_4 .inner2 .btn {
    margin: 0 auto !important;
  }
}

.cont_5 {
  background: url(../images/common/bg_1.jpg) center no-repeat;
  background-size: cover;
  padding: 80px 0;
}

@media only screen and (max-width: 1200px) {
  .cont_5 {
    padding: 80px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_5 {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_5 {
    padding: 50px 40px;
  }
}

.newswrap {
  height: 400px;
}

.newswrap .news {
  overflow-y: auto;
  z-index: 1;
  position: relative;
  height: 100%;
  padding-right: 40px;
}

@media only screen and (max-width: 640px) {
  .newswrap .news {
    padding-right: 0;
  }
}

.newswrap .news .blog_list {
  border: 0;
}

.newswrap .news .blog_list .blog_photo {
  display: none;
}

.newswrap .news .blog_list > div {
  border-bottom: 1px dashed #fff;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.newswrap .news .blog_list .blog_text {
  width: 100%;
  padding: 0;
  font-size: 16px;
}

.newswrap .news .blog_list .blog_text .blog_date {
  line-height: 1;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
}

.newswrap .news .blog_list .blog_text ul {
  display: none;
}

.newswrap .news .blog_list .blog_text h3 {
  margin-bottom: 0;
}

.newswrap .news .blog_list .blog_text h3 a span {
  display: block;
  font-weight: 300;
  font-size: 16px;
  color: #fff;
}

.newswrap .news .blog_list .blog_text .detail {
  display: none;
}

.newswrap .news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #fff;
  display: block !important;
}

.newswrap .news dl dt, .newswrap .news dl dd {
  color: #fff;
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

/* title */
h2.mail.title {
  display: none !important;
}

.midashi h2 {
  width: 100%;
  color: #3f9add;
  font-weight: 700;
  line-height: 1.7em;
  font-size: 30px;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .midashi h2 {
    font-size: 25px;
  }
}

.midashi h2:after {
  margin-top: 5px;
  content: "";
  height: 5px;
  width: 100%;
  display: block;
  background: url(../images/common/pt_01.png) repeat-x center top;
}

.midashi2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.midashi2 h2 {
  display: inline-block;
  color: #fff;
  font-weight: 700;
  line-height: 1.7em;
  font-size: 30px;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

.midashi2 h2:after {
  margin-top: 5px;
  content: "";
  height: 5px;
  width: 100%;
  display: block;
  background: url(../images/common/pt_01.png) repeat-x center top;
}

.midashi3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.midashi3 h2 {
  display: inline-block;
  color: #3f9add;
  font-weight: 700;
  line-height: 1.7em;
  font-size: 30px;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .midashi3 h2 {
    font-size: 25px;
  }
}

.midashi3 h2:after {
  margin-top: 5px;
  content: "";
  height: 5px;
  width: 100%;
  display: block;
  background: url(../images/common/pt_01.png) repeat-x center top;
}

.midashi4 {
  width: 100%;
}

.midashi4 h2 {
  font-weight: 700;
  background: #f3f3f3;
  padding: 1em 0.5em 1em 1.5em;
  position: relative;
  font-size: 25px;
}

@media only screen and (max-width: 1000px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

.midashi4 h2:after {
  content: "";
  width: 5px;
  height: 20px;
  border-radius: 500px;
  background: #3f9add;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.midashi5 h3 {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  background: linear-gradient(transparent 70%, #a7d6ff 70%);
}

@media only screen and (max-width: 1000px) {
  .midashi5 h3 {
    font-size: 18px;
  }
}

.midashi6 {
  width: 100%;
}

.midashi6 h2 {
  font-weight: 700;
  background: #f3f3f3;
  padding: 1em 0.5em 1em 1.5em;
  position: relative;
  font-size: 25px;
}

@media only screen and (max-width: 1000px) {
  .midashi6 h2 {
    text-align: center !important;
    font-size: 20px;
  }
}

.midashi6 h2 span {
  background: #3f9add;
  color: #fff;
  font-size: 0.8em;
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
}

@media only screen and (max-width: 640px) {
  .midashi6 h2 span {
    display: block;
    margin-bottom: 10px;
  }
}

.midashi7 h4 {
  font-weight: 700;
  border-bottom: 3px solid #ffa0c3;
  padding-bottom: 5px;
  font-size: 20px;
}

.faq01 dl {
  margin-bottom: 30px;
  display: block !important;
}

.faq01 dl dt {
  margin-bottom: 10px !important;
}

.faq01 dd, .faq01 dt {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background: none !important;
}

.faq01 dt {
  font-size: 16px;
  border-bottom: 1px #1b4996 dashed;
  padding-bottom: 20px !important;
  position: relative;
  color: #1b4996;
  font-weight: bold;
  position: relative;
  padding-left: 3em !important;
}

.faq01 dt:before {
  content: "Q";
  display: block;
  background: #1b4996;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -3px;
}

.faq01 dd {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px #376eca solid;
  padding-bottom: 30px !important;
  position: relative;
  padding-left: 3em !important;
}

.faq01 dd:before {
  content: "A";
  display: block;
  background: #376eca;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -6px;
}

.works01 .box {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.works01 .box h3 {
  background: #3f9add;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  margin: 15px 0 0;
}

.works01 .box div {
  margin: 10px 0 0;
}

.ancinner > div {
  margin-top: -100px;
  padding-top: 100px;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1000px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl2 {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1000px) {
  .flex3cl2 {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl2 {
    justify-content: space-between;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl2 .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .flex3cl2 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl2 .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl2 .box img {
  width: 100% !important;
}

.flex3cl2 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl2 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex2cl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .flex2cl {
    flex-direction: column-reverse;
  }
}

.flex2cl:nth-child(odd) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .flex2cl:nth-child(odd) {
    flex-direction: column-reverse;
  }
}

.flex2cl .flxL {
  background: #fff;
  width: 57%;
}

@media only screen and (max-width: 834px) {
  .flex2cl .flxL {
    width: 100%;
  }
}

.flex2cl .flxR {
  width: 40%;
}

@media only screen and (max-width: 834px) {
  .flex2cl .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl .flxR .img {
  width: 100%;
}

.flex2cl .flxR .img img {
  width: 100% !important;
}

.flex2cl2 {
  display: flex;
  justify-content: space-between !important;
  flex-wrap: wrap;
  flex-direction: row;
}

.flex2cl2 .box {
  position: relative;
  width: 47.5609%;
  display: block;
  margin-bottom: 15px;
}

@media only screen and (max-width: 640px) {
  .flex2cl2 .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex2cl2 .box img {
  width: 100% !important;
}

.waku {
  background: #3f9add;
  padding: 10px;
}

.waku .wakuinner {
  padding: 30px;
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .waku .wakuinner {
    padding: 20px;
  }
}

.waku .wakuinner .wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .waku .wakuinner .wrap {
    display: block !important;
  }
}

@media only screen and (max-width: 834px) {
  .waku .wakuinner .wrap .box {
    width: 100% !important;
  }
}

.waku .wakuinner .wrap .box:first-child {
  width: 70%;
}

.waku .wakuinner .wrap .box:nth-child(2) {
  width: 28%;
}

.waku .wakuinner .wrap .box img {
  width: 100% !important;
}

.kodawari01 {
  background: #d7aeda;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  display: inline-block;
}

.waku2 {
  border: 5px solid #ccc;
  background: #f7f7f7;
  padding: 20px;
}

.price td {
  text-align: center !important;
  background: #fff;
}

.concept {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .concept {
    flex-direction: column-reverse;
  }
}

.concept:nth-child(odd) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .concept:nth-child(odd) {
    flex-direction: column-reverse;
  }
}

.concept:nth-child(odd) .flxR {
  margin-left: 0;
  margin-right: auto;
}

.concept .flxL {
  background: #fff;
  position: absolute;
  padding: 5%;
  width: 60%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1200px) {
  .concept .flxL {
    width: 65%;
  }
}

@media only screen and (max-width: 834px) {
  .concept .flxL {
    width: 100%;
    padding: 10%;
    position: static;
  }
}

.concept .flxR {
  margin-left: auto;
  width: 60%;
}

@media only screen and (max-width: 834px) {
  .concept .flxR {
    width: 100%;
  }
}

.concept .flxR .img {
  width: 100%;
}

.concept .flxR .img img {
  width: 100% !important;
}

.staff01 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -2.4390%;
}

@media only screen and (max-width: 834px) {
  .staff01 {
    margin-left: 0;
  }
}

.staff01 .staff02 {
  margin-bottom: 30px;
  margin-left: 2.4390%;
  width: 47.5609%;
  background: #f7f7f7;
  padding: 30px;
}

@media only screen and (max-width: 834px) {
  .staff01 .staff02 {
    width: 100%;
    margin-left: 0;
  }
}

.staff01 .staff02 .box {
  position: relative;
}

.staff01 .staff02 h2 {
  border-top: 5px solid #fff;
  padding-top: 10px;
  font-size: 18px;
  font-weight: 700;
}

.staff01 .staff02 h2:before {
  content: "";
  height: 5px;
  width: 200px;
  background: #3f9add;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.staff01 .staff02 b {
  border-radius: 20px;
  margin: 10px 0;
  background: #ffa0c3;
  color: #fff;
  padding: 2px 5px;
  display: inline-block;
}

.voice01 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  background: #f7f7f7;
  padding: 10px;
}

.voice01 .voice02 {
  border: solid 1px #ccc;
  padding: 40px;
}

@media only screen and (max-width: 640px) {
  .voice01 .voice02 {
    padding: 20px;
  }
}

.voice01 .voice02 .box {
  position: relative;
}

.voice01 .voice02 h2 {
  border-top: 5px solid #fff;
  padding-top: 10px;
  font-size: 18px;
  font-weight: 700;
}

.voice01 .voice02 h2:before {
  content: "";
  height: 5px;
  width: 200px;
  background: #3f9add;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.voice01 .voice02 b {
  border-radius: 20px;
  margin: 10px 0;
  background: #ffa0c3;
  color: #fff;
  padding: 2px 5px;
  display: inline-block;
}

.bg01 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: #f5f4f3;
  padding: 80px 0;
}

@media only screen and (max-width: 1200px) {
  .bg01 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bg01 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .bg01 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 640px) {
  .comp01 dl {
    display: flex;
    flex-flow: column;
  }
  .comp01 dt {
    border-bottom: 0 !important;
    font-weight: bold;
  }
  .comp01 dt, .comp01 dd {
    display: flex !important;
    padding: 5px !important;
    display: block;
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap;
  }
}

.company01 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .company01 {
    display: block;
  }
}

.company01 .ttl {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  background: #fff;
  padding: 10px;
  background: #fdbb2a;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .company01 .ttl {
    position: static;
    height: auto;
  }
}

.company01 .wrap {
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .company01 .wrap {
    flex-wrap: inherit !important;
  }
}

.company01 .wrap .box {
  margin-right: 10px;
}

.company01 .flxL {
  position: relative;
  background: #fff;
  padding: 60px 20px 20px;
  width: 48%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 834px) {
  .company01 .flxL {
    width: 100%;
    padding: 10%;
  }
}

.company01 .flxR {
  position: relative;
  background: #fff;
  padding: 60px 20px 20px;
  width: 48%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 834px) {
  .company01 .flxR {
    width: 100%;
    padding: 10%;
  }
}

.company01 .flxR .img {
  width: 100%;
}

.company01 .flxR .img img {
  width: 100% !important;
}

.company_area dt, .company_area dd {
  background: none !important;
  border: none !important;
  padding: 1.5em 1em !important;
  font-size: 1rem;
}

.company_area dt {
  border-bottom: 1px solid #ccc !important;
}

.company_area dd {
  border-bottom: 1px solid #eee !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .company_area dt {
    width: 30% !important;
  }
  .company_area dd {
    width: 70% !important;
  }
}

@media only screen and (max-width: 640px) {
  .company_area dl {
    margin-top: -1px;
    border: 1px solid #ccc;
    display: flex;
    flex-flow: column;
  }
  .company_area dt {
    color: #fff;
    background: #159fde !important;
    font-weight: bold;
  }
  .company_area dd {
    border-bottom: 0 !important;
    background: #fff !important;
  }
  .company_area dt, .company_area dd {
    align-items: center !important;
    padding: 10px !important;
    display: block;
    width: 100% !important;
    text-align: center;
  }
}

.about_01 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: #f5f4f3;
  padding: 80px 0;
}

@media only screen and (max-width: 1200px) {
  .about_01 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .about_01 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .about_01 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.about_01:nth-child(odd) {
  background: none;
}

.about_01 .img {
  justify-content: center;
}

.about_02wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .about_02wrap {
    display: block;
  }
}

.about_02wrap .about_02 {
  background: #eaeaea;
  padding: 20px;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .about_02wrap .about_02 {
    width: 100%;
    margin-bottom: 20px;
  }
}

.about_02wrap .about_02 .img {
  justify-content: center;
}

.about_02wrap .about_02 .midashi5 article {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.about_02wrap .about_02 .midashi5 article div {
  margin-left: 20px;
  max-width: 100px;
  background: red;
  padding: 2px 5px;
  color: #fff;
}

.about_02wrap .about_02 .midashi5 article div:empty {
  display: none;
}

.about_02wrap .about_02 .flxL {
  width: 100%;
  margin-bottom: 20px;
}

.about_02wrap .about_02 .flxL .img {
  flex-wrap: inherit !important;
}

.about_02wrap .about_02 .flxR {
  width: 100%;
}

.about_02wrap .about_02 .flxR .btn3 {
  max-width: 100%;
  width: 100%;
}

.slider02 {
  width: 100%;
}

.slider02 .slick-prev, .slider02 .slick-next {
  top: 40% !important;
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-prev, .slider02 .slick-next {
    top: 30% !important;
  }
}

.slider02 .slick-prev {
  left: 10px !important;
  z-index: 1;
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-prev {
    left: 0 !important;
  }
}

.slider02 .slick-next {
  right: 10px !important;
  z-index: 1;
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-next {
    right: 0 !important;
  }
}

.slider02 .slick-slide {
  height: 670px;
  max-width: 1000px;
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-slide {
    height: auto;
  }
}

.slider02 .slick-slide img {
  width: 100% !important;
  height: auto !important;
}

.slider02 .slick-slide article div {
  margin-bottom: 20px;
  font-size: 16px;
}

.slider02 .slick-list {
  padding: 10px 0;
}

.slider02 .slick-dots {
  position: static !important;
  display: -webkit-flex !important;
  display: flex !important;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-dots {
    margin-top: 20px !important;
  }
}

.slider02 .slick-dots li {
  list-style: none;
  width: 13.2% !important;
  margin: 0 !important;
  margin-right: 1.2% !important;
  margin-bottom: 10px !important;
  height: 100px !important;
  object-fit: contain !important;
  font-family: "object-fit: contain!important;";
  overflow: hidden;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .slider02 .slick-dots li {
    width: 18.8% !important;
  }
}

@media only screen and (max-width: 640px) {
  .slider02 .slick-dots li {
    height: 25% !important;
  }
}

.slider02 .slick-dots li:nth-of-type(7n) {
  margin-right: 0 !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .slider02 .slick-dots li:nth-of-type(7n) {
    margin-right: 1.2% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .slider02 .slick-dots li:nth-of-type(5n) {
    margin-right: 0;
  }
}

.slider02 .slick-dots li img {
  width: auto !important;
}

.oem01 ul {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.oem01 ul li {
  width: 32%;
}

.about_03 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .about_03 {
    display: block;
  }
}

.about_03 .img {
  justify-content: center;
}

.about_03 .midashi5 article {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.about_03 .midashi5 article div {
  margin-left: 20px;
  max-width: 100px;
  background: red;
  padding: 2px 5px;
  color: #fff;
}

.about_03 .midashi5 article div:empty {
  display: none;
}

.about_03 .flxL {
  width: 40%;
}

@media only screen and (max-width: 640px) {
  .about_03 .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
}

.about_03 .flxL .img {
  flex-wrap: inherit !important;
}

.about_03 .flxL .img img {
  width: 100% !important;
}

.about_03 .flxR {
  width: 55%;
}

@media only screen and (max-width: 640px) {
  .about_03 .flxR {
    width: 100%;
  }
  .about_03 .flxR .btn {
    width: 100%;
  }
}

a.itext {
  width: 100% !important;
  height: auto !important;
}

.guide01 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 640px) {
  .guide01 {
    display: block;
  }
}

.guide01 .flxL {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .guide01 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.guide01 .flxR {
  width: 65%;
  margin-left: 5%;
}

@media only screen and (max-width: 640px) {
  .guide01 .flxR {
    width: 100%;
    margin-left: 0;
  }
}

.contact01 {
  background: #f3f3f3;
  margin: 0 auto;
  padding: 50px;
}

.contact01 dl {
  margin-bottom: 40px;
  font-size: 15px;
}

.contact01 dl:last-child {
  margin-bottom: 0;
}

.contact01 dl dt {
  line-height: 1;
  font-size: 16px;
  max-width: 200px;
  min-width: 200px;
  width: 30% !important;
  font-weight: 700;
  border: 0 !important;
  background-color: inherit !important;
  padding: 0 !important;
  justify-content: flex-start !important;
}

@media only screen and (max-width: 640px) {
  .contact01 dl dt {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
}

.contact01 dl dt label {
  margin-top: 5px;
  background: #ffa0c3;
  border-radius: 5px;
  color: #fff !important;
  font-size: 13px;
  font-weight: 700;
  padding: 6px;
  line-height: 1;
}

@media only screen and (max-width: 640px) {
  .contact01 dl dt label {
    line-height: 1;
    padding: 3px;
    font-size: 11px;
  }
}

.contact01 dl dd {
  text-align: left;
  width: 80% !important;
  border: 0 !important;
  background-color: inherit !important;
  padding: 0 !important;
}

@media only screen and (max-width: 640px) {
  .contact01 dl dd {
    width: 100% !important;
  }
}

.contact01 dl dd span {
  line-height: 1;
  margin-right: 0;
}

.contact01 .file {
  border-top: 0 !important;
  flex-direction: row !important;
  border-top: 0 !important;
}

@media only screen and (max-width: 640px) {
  .contact01 .file {
    flex-direction: column !important;
  }
}

.contact01 .file dt {
  font-size: 16px;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
}

@media only screen and (max-width: 640px) {
  .contact01 .file dt {
    flex-direction: row !important;
  }
}

.contact01 .file dd label {
  position: static !important;
  margin-left: auto !important;
  margin-bottom: 10px;
}

.contact01 .file dd div {
  line-height: 1.4em;
  color: #666;
  font-size: 14px;
  letter-spacing: -0.05em;
}

.policy a {
  color: #333;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media only screen and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title,
.wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2,
.item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a,
.item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover,
.item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}
